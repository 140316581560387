import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import bg from "../../../assets/images/testimonials/fixed.png";

export default function ClassicStory() {
  const divRef = useRef(null);
  const [isInView, setIsInView] = useState(false);

  useEffect(() => {
    const options = {
      root: null, // Use the viewport as the root
      rootMargin: '0px', // No margin
      threshold: 0, // 0.5 means when 50% of the element is visible
    };

    const handleIntersection = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setIsInView(true);
        } else {
          setIsInView(false);
        }
      });
    };

    const observer = new IntersectionObserver(handleIntersection, options);

    if (divRef.current) {
      observer.observe(divRef.current);
    }

    // Cleanup the observer on component unmount
    return () => {
      if (divRef.current) {
        observer.unobserve(divRef.current);
      }
    };
  }, [divRef]);
  return (
    <ClassicStorys ref={divRef}>
      <Wrapper className={'wrapper'}>
      <StoryDetail>
        {
          isInView && <img loading="lazy" src={bg} className="fix-image" alt="" />
        }
        <CoverStory>
          <CoverTitle>
            <SubtitleStry>INTERIOR DESIGNERS IN KOCHI</SubtitleStry>
            <Line type="str"></Line>
            <BigTitle>Classic Insides</BigTitle>
            <StrTitle>THE CLASSIC STORY</StrTitle>
          </CoverTitle>
        </CoverStory>
        <PAraOne>
          Classic Insides, the best interior designers in Kochi, works with
          unity and discipline in our craft. We infuse your home with serenity,
          crafting spaces that resonate with comfort and embrace your soul. With
          meticulous attention to detail, our experienced team sculpts
          dreamscapes that radiate peace and invite you to unwind in an
          environment tailored to your comfort. At Classic Insides, we don't
          just design interiors; we create sanctuaries where serenity finds its
          eternal home.
        </PAraOne>
        <Button href="https://classicinsides.com/about" target="_blank">
            Read More
          </Button>
      </StoryDetail>
      </Wrapper>
    </ClassicStorys>
  );
}

const CoverStory = styled.div`
  display: flex;
  justify-content: space-between;
`;
const Line = styled.div`
  background-color: var(--orange);
  width: 60px;
  height: 1px;
  margin: ${({ type }) => (type == "str" ? "" : "0 auto")};
`;
const CoverTitle = styled.div`
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;
const CardCover = styled.div`
  width: 100%;
  padding: 5px;
  box-sizing: border-box;
`;
const Location = styled.h3`
  text-align: center;
`;
const ClassicStorys = styled.div`
  padding: 80px 0;
  height: 100vh;
  display: flex;
  justify-content: center;

  align-items: center;

  @media (max-width: 1300px) {
    flex-direction: column;
  }
  @media (max-width: 480px) {
    width: 100%;
  }
`;
const ImageSection = styled.div`
  width: 50%;
  @media (max-width: 1300px) {
    width: 100%;
  }

  img {
    height: 100% !important;
  }
`;
const StoryDetail = styled.div`
  box-sizing: border-box;
  background-color: #ffffffe0;
  width: 40%;
  margin: 0 0;
  padding: 30px;
  box-sizing: border-box;

  @media(max-width:1375px){
    width:50%;
  }

  @media(max-width:1080px){
    width:60%;
  }

  @media(max-width:901px){
    width:80%;
    margin: 0 auto;
  }

  @media(max-width:700px){
    width:100%;
    margin: 0 auto;
  }
`;
const SubtitleStry = styled.div`
  font-family: "gellix-regular";
  letter-spacing: 2px;
  margin-bottom: 20px;
  @media (max-width: 480px) {
    font-size: 11px;
    margin-bottom: 10px;
  }
`;
const StrTitle = styled.h1`
  font-size: 44px;
  letter-spacing: 1px;
  color: #3f3d56;
  margin-bottom: 20px;
  font-family: 'gellix-regular';

  @media (max-width: 1600px) {
    font-size: 40px;
  }

  @media (max-width: 1430px) {
    font-size: 37px;
  }
  @media (max-width: 700px) {
    font-size: 33px;
  }
  @media (max-width: 480px) {
    font-size: 28px;
  }
  @media (max-width: 425px) {
    font-size: 23px;
    margin-top: 10px;
  }
`;

const BigTitle = styled.div`
      margin-top: 20px;
      color: #3F3D56;
      text-align: center;
      font-family: 'gellix-medium';
      font-size: 40px;
      font-style: normal;
      font-weight: 500;
      line-height: 70px; /* 137.255% */
      letter-spacing: 2.55px;
      text-transform: uppercase;

      @media(max-width:700px){
          letter-spacing: unset;
          font-size: 38px;
      }

      @media(max-width:425px){
          font-size: 32px;
      }

      @media(max-width:350px){
          font-size: 28px;
      }
`;

const PAraOne = styled.p`
  line-height: 26px;
  text-align: justify;

  @media (max-width: 1600px) {
    font-size: 0.9rem;
  }
`;
const ParaTwo = styled.p`
  text-align: justify;
  line-height: 26px;
  margin-bottom: 20px;

  @media (max-width: 1600px) {
    font-size: 0.9rem;
  }
`;
const Button = styled.a`
  border: 1px solid var(--orange);
  height: 40px;
  font-size: 14px;
  display: flex;
  color: #000;
  justify-content: center;
  align-items: center;
  width: 150px;
  cursor: pointer;
  margin: 0 auto;
  margin-top: 20px;
  @media (max-width: 480px) {
    font-size: 12px;
    height: 30px;
    width: 100px;
  }
`;

const Wrapper = styled.div``;
