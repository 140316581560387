import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { accountsConfig } from "../../../axiosConfig";
import ReactPlayer from "react-player";
import Slider from "react-slick";
import arrowLeft from "../../../assets/images/apartments/gallery/newDesign/icons/leftArr.svg";
import arrowRight from "../../../assets/images/apartments/gallery/newDesign/icons/rightarr.svg";
import { useNavigate } from "react-router-dom";

export default function Celebrations() {
  const [eventList, setEventList] = useState([]);
  const sliderRef = useRef();

  useEffect(() => {
    accountsConfig.get("customer_celebrations/list-all-events/").then((res) => {
      if (res.data.app_data.StatusCode == 6000) {
        setEventList(res.data.app_data.data);
      } else {
        setEventList([]);
      }
    });
  }, []);

  useEffect(() => {
    if (window.innerWidth < 600) {
      sliderRef.current.classList.add("set-first-slide");
    }
  }, []);

  const navigate = useNavigate();
  const settings = {
    dots: false,
    arrows: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    beforeChange: (oldIndex, newIndex) => {
      if (newIndex < 0) {
        return;
      }

      if (sliderRef.current.classList.contains("set-first-slide")) {
        sliderRef.current.classList.remove("set-first-slide");
      }
    },
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <Container>
      <Wrapper className="wrapper">
        <Content>
          <SubTitle>A GLIMPSE INTO EVENTS & KEY HANDOVERS</SubTitle>
          <Line></Line>
          <Title onClick={() => navigate("/our-community")}>
            {" "}
            CUSTOMER CELEBRATIONS
          </Title>
          <Cards ref={sliderRef}>
            <Slider {...settings}>
              {eventList.map((item, index) => {
                if (item?.type === "image") {
                  return (
                    <Card
                      onClick={() =>
                        navigate(
                          item?.celebration_type === "event"
                            ? "/events"
                            : "/key-handover"
                        )
                      }
                      className="card"
                    >
                      <img src={item?.image} alt="" />
                      <BottomOverlay >{item?.event} - {item?.year}</BottomOverlay>
                    </Card>
                  );
                } else {
                  return (
                    <Card
                      onClick={() =>
                        navigate(
                          item?.celebration_type === "event"
                            ? "/events"
                            : "/key-handover"
                        )
                      }
                      className="card"
                    >
                      <ReactPlayer
                        playing
                        playsinline
                        url={
                          item?.type === "video"
                            ? item?.videos
                            : item?.url_field
                        }
                        muted
                        width={385}
                        height={265}
                        loop
                        controls={false}
                      />
                      <BottomOverlay className={item?.type === "video" ? 'video' : 'url' }>{item?.event} - {item?.year}</BottomOverlay>
                    </Card>
                  );
                }
              })}
            </Slider>
          </Cards>
        </Content>
      </Wrapper>
    </Container>
  );
}

const Container = styled.div`
  background-color: #fff;
  padding-bottom: 100px;
`;
const Wrapper = styled.div``;
const Content = styled.div``;
const SubTitle = styled.h3`
  font-family: "gellix-light";
  letter-spacing: 2px;
  text-align: center;
  margin-bottom: 20px;
`;
const Line = styled.div`
  background-color: var(--orange);
  width: 60px;
  height: 1px;
  margin: ${({ type }) => (type == "str" ? "" : "0 auto")};
`;
const Title = styled.h1`
  font-family: "gellix-medium";
  color: var(--orange);
  text-align: center;
  margin: 20px 0;
  text-transform: uppercase;
  cursor: pointer;
  @media (max-width: 480px) {
    font-size: 20px;
    letter-spacing: 2px;
  }
`;

const Cards = styled.div`
  .slick-list {
    display: flex !important;
    flex-direction: column;
    gap: 25px;
  }

  .slick-next {
    top: calc(100% + 25px) !important;
    right: 38% !important;
    display: block !important;
  }
  .slick-prev {
    top: calc(100% + 25px) !important;
    left: 35% !important;
    display: block !important;
  }

  .slick-next:before {
    display: flex !important;
    content: "" !important;
    background-image: url(${arrowRight}) !important;
    background-size: 100px;
    background-repeat: no-repeat;
    background-size: contain;
    height: 60px;
    width: 70px !important;
  }
  .slick-prev:before {
    display: flex !important;
    content: "" !important;
    background-image: url(${arrowLeft}) !important;
    background-size: contain;
    background-repeat: no-repeat;
    height: 60px;
    width: 70px !important;
  }

  &.set-first-slide {
    .slick-track {
      transform: translate3d(0, 0, 0) !important;
    }
  }

  @media (max-width: 580px) {
    .slick-next {
      right: calc(15% + 50px) !important;
    }
    .slick-prev {
      left: calc(15%) !important;
    }
  }
`;
const Card = styled.div`
  display: block;
  min-height: 150px;
  width: 32%;
  margin: 0 10px;
  cursor: pointer;
  height: 300px;
  position: relative;

  div {
    width: 95% !important;
    height: 100% !important;
  }

  img {
    width: 95%;
    height: 100%;
    object-fit: cover;
  }

  video {
    width: 95%;
    height: 100%;
    object-fit: cover;
  }

  &::after {
    content: "";
    position: absolute;
    inset: 0;
  }
`;

const BottomOverlay = styled.span`
  position: absolute;
  bottom: 0;
  height: 10%;
  width:95%;
  border: 1px solid rgba(255, 255, 255, 0.5);
  background: linear-gradient(
    270deg,
    #eb6502 0%,
    rgba(235, 101, 2, 0.5) 201.29%
  );
  color: #fff;
  text-align: center;
  font-size: 18px;
  font-style: normal;
  line-height: normal;
  display: flex;
  align-items: center;
  justify-content: center;

  &.url{
    width:90%;
  }
`;
