import React from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import Home from "../../screens/Home";
import About from "../../screens/About";
import Contact from "../../screens/Contact";
import Blogs from "../../screens/Blogs";
import Services from "../../screens/Services";
import Apartments from "../../screens/Apartments";
import ApartmentsSingle from "../../includes/apartments/ApartmentsSingle";
import Villas from "../../screens/Villas";
import CustomerCelebration from "../../screens/CustomerCelebration";
import KeyHandler from "../../includes/customer/KeyHandler";
import Events from "../../includes/events/Events";
import BlogInner from "../../screens/BlogInner";
import ScrollTop from "../../includes/helper/ScrollTop";
import PageNotFound from "../../screens/404";
import { oldUrlAndNewurl } from "../../general/data/data";
import HomeCareServices from "../../screens/HomeCareServices";

function AppRouter() {
  return (
    <>
      <ScrollTop />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/services" element={<Services />} />
        <Route path="/apartments-for-sale-in-kochi" element={<Apartments />} />
        <Route path="/villas-for-sale-in-kochi" element={<Villas />} />
        <Route
          path="/apartments-for-sale-in-kochi/:id"
          element={<ApartmentsSingle />}
        />
        <Route
          path="/villas-for-sale-in-kochi/:id"
          element={<ApartmentsSingle />}
        />
        <Route path="/contact" element={<Contact />} />
        <Route path="/our-community" element={<CustomerCelebration />} />
        <Route path="/key-handover" element={<KeyHandler />} />
        <Route path="/events" element={<Events />} />

        <Route path="/blogs" element={<Blogs />} />
        <Route path="/blog/:slug" element={<BlogInner />} />
        <Route path="/home-care-services" element={<HomeCareServices />} />
        
        {/* for seo -redirect oldlinks to newlinks */}
        {oldUrlAndNewurl.map((item) => (
          <Route path={item?.old} element={<Navigate to={item?.new} />} />
        ))}
        <Route path="/*" element={<PageNotFound />} />
      </Routes>
    </>
  );
}

export default AppRouter;
