import React, { useEffect, useState } from "react";
import styled from "styled-components";
import breadCrumb from "../../../../assets/images/about/breadCrumb/Breadcrumb.jpg";
import { getFirstAndBalanceWords } from "../../../general/common/helper";

function Banner({ getSingleProject }) {
  const [result, setResult] = useState("");
  useEffect(() => {
    if (getSingleProject?.name) {
      setResult(getFirstAndBalanceWords(getSingleProject.name));
    }
  }, [getSingleProject])
  return (
    <Section bg={breadCrumb}>
      <Content>
        {/* <Heading>{getSingleProject?.name}</Heading> */}
        <Title>
            {result?.firstWord} {" "}
            <span>{result?.balanceWords}</span>
        </Title>
        <Line />
        {getSingleProject?.name === "CLASSIC AVENIR SUITES" && <SubHeading>POWERED BY SOLAR</SubHeading>}
      </Content>
    </Section>
  );
}

export default Banner;

const Section = styled.div`
  height: calc(400px - 83px);
  display: flex;
  justify-content: center;
  align-items: center;
  background-position: center center;
  background-size: cover;
  /* background-image: url(${(props) => props?.bg}); */
  background-color: #fff;
  position: relative;

  /* &::after {
    content: "";
    display: block;
    position: absolute;
    inset: 0;
    background: rgba(0, 0, 0, 0.25);
  } */

  @media screen and (max-width: 580px) {
      height: calc(250px - 83px);
  }
`;

const Title = styled.h2`
  color: #606060;
  letter-spacing: 2px;
  font-family: "gellix-bold";
  font-size: 48px;
  span {
    color: #eb6502;
  }
  @media all and (max-width: 1440px) {
    font-size: 54px;
  }
  @media all and (max-width: 768px) {
    font-size: 46px;
  }
  @media all and (max-width: 768px) {
    font-size: 28px;
  }

  @media(max-width:580px){
    text-align: center;
  }
`;

const Content = styled.div`
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  z-index: 1;
`;
const Heading = styled.div`
  color: #fff;
  font-family: "gellix-medium";
  font-size: 60px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 3.75px;
  text-transform: uppercase;
  @media all and (max-width: 768px) {
    font-size: 40px;
  }
  @media all and (max-width: 480px) {
    text-align: center;
    font-size: 28px;
  }
`;
const Line = styled.div`
  height: 1px;
  width: 60px;
  background-color: #000000;
`;
const SubHeading = styled.div`
  color: #000000;
  font-family: "gellix-regular";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 3.2px;
  text-transform: uppercase;

  @media all and (max-width: 480px) {
    font-size: 14px;
    text-align: center;
  }
  @media all and (max-width: 400px) {
    font-size: 12px;
    width: 80%;
  }
`;
