import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";

//imports
import { useNavigate } from "react-router-dom";
import Slider from "react-slick";
import ReactPlayer from "react-player";
import { accountsConfig } from "../../../axiosConfig";

function Spotlight() {
  const [videos, setvideos] = useState([]);
  const navigate = useNavigate();
  const [states, setStates] = useState({});
  const sliderRef = useRef();
  const sliderVideoRefs = useRef({});

  
  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    autoplay: true,
    speed: 500,
    autoplaySpeed: 10000,
    pauseOnHover: true,
    cssEase: "linear",
    afterChange: (currentSlide) => {
      const allFalseItems = Object.fromEntries(
        Object.keys(states).map((key) => [key, false])
      );
      setStates(allFalseItems);
      setStates((prev) => ({ ...prev, [currentSlide]: true }));
    },
  };
  
  useEffect(() => {
    accountsConfig.get("web/list-banner-video/", {}).then((res) => {
      const data = res.data.app_data.data;
      if (res.data.app_data.StatusCode == 6000) {
        setvideos(data);
        data.map((obj, ind) => {
          if (ind === 0) {
            setStates((prev) => ({ ...prev, [ind]: true }));
          } else {
            setStates((prev) => ({ ...prev, [ind]: false }));
          }
        });
      } else {
        setvideos([]);
      }
    });
  }, []);

  const handleAutoplay = () => {
    if (sliderRef.current) {
      // Go to the next slide
      sliderRef.current.slickNext();
    }
  };

  const seekToFirst = (position) => {
      sliderVideoRefs.current[`ref-${position}`].seekTo(0);
  };


  return (
    <Container className="spotlight">
      <Slider {...settings} ref={sliderRef}>
        {videos?.map((item, ind) => {
          if (item?.desktop_video) {
            return (
              <Item className="item">
                <ReactPlayer
                  className="player"
                  url={item?.desktop_video}
                  width="100%"
                  height="100%"
                  style={{ objectFit: "cover" }}
                  playing={states[ind]}
                  playsinline
                  muted
                  loop
                  controls={false}
                />
                <Content className="content">
                  <TextContainer className="content">
                    <Title>
                      Journey through Our <br /> Classic Legacy
                    </Title>
                    <Button
                      onClick={() => navigate("/villas-for-sale-in-kochi")}
                      className="content"
                    >
                      Explore More
                    </Button>
                  </TextContainer>
                </Content>
              </Item>
            );
          } else {
            return (
              <Item className="item">
                <ReactPlayer
                  className="player"
                  url={item?.url}
                  width="100%"
                  height="100%"
                  style={{ objectFit: "cover" }}
                  playing={states[ind]}
                  playsinline
                  muted
                  controls={false}
                  loop
                />

                <Content className="content">
                  <TextContainer className="content">
                    <Title>
                      Journey through Our <br /> Classic Legacy
                    </Title>
                    <Button
                      onClick={() => navigate("/villas-for-sale-in-kochi")}
                      className="content"
                    >
                      Explore More
                    </Button>
                  </TextContainer>
                </Content>
              </Item>
            );
          }
        })}
      </Slider>
    </Container>
  );
}

export default Spotlight;

const Container = styled.div`
  height: calc(100vh - 82px);
  position: relative;
  color: #f2f2f2;
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #ffffff;

  .slick-next {
    right: 10px !important;
    z-index: 2;
  }

  .slick-prev {
    left: 10px !important;
    z-index: 2;
  }

  div:not(.content) {
    height: 100% !important;
  }

  @media screen and (max-width:580px) {
      height: calc(80vh - 82px);
  }
`;
const Wrapper = styled.div``;
const Play = styled.div`
  position: absolute;
  left: 50%;
  cursor: pointer;
  font-size: 35px;
  border: 1px solid #fff;
  border-radius: 50%;
  width: 50px;
  transform: translate(-50%, -50%);
  top: 50%;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const Content = styled.div`
  position: absolute;
  z-index: 10;
  bottom: 5%;
  left: 10%;
  @media all and (max-width: 580px) {
    bottom: 1%;
  }
`;
const TextContainer = styled.div`
  margin-bottom: 80px;

  @media screen and (max-width: 580px) {
    margin-bottom: 40px;
  }
`;
const Title = styled.h1`
  font-size: 45px;
  font-family: "gellix-light";
  margin-bottom: 20px;
  text-transform: uppercase;

  @media (max-width: 968px) {
    font-size: 40px;
  }
  @media (max-width: 425px) {
    font-size: 35px;
  }
  @media (max-width: 375px) {
    font-size: 26px;
  }

  @media screen and (max-width: 580px) {
    br {
      display: none;
    }
  }
`;
const Button = styled.div`
  border: 1px solid var(--orange);
  width: fit-content;
  height: 40px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 150px;
`;

const Item = styled.div`
  width: 100vw !important;
  height: calc(100vh) !important;
  position: relative;
  color: #fff;
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;

  iframe {
    height: calc(100vh) !important;
    width: 100%;

    /* .html5-video-container {
      video {
        width: 100% !important;
        left: 0 !important;
        background-color: red !important;
      }
    } */
  }

  video {
    object-fit: cover !important;
    width: 100vw !important;
  }

  &::after {
    content: "";
    position: absolute;
    inset: 0;
  }
`;
